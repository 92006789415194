import React, { useState, useRef, useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { StaffService } from "../../services/dataService";
import FullScreenLoading from "./FullScreenLoading"; // Import the new component
import { motion } from "framer-motion";

// Add custom scrollbar styles
const scrollbarStyles = `
  /* Custom scrollbar styles */
  .custom-scrollbar::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }
  
  .custom-scrollbar::-webkit-scrollbar-track {
    background: #f1f5f9;
    border-radius: 4px;
  }
  
  .custom-scrollbar::-webkit-scrollbar-thumb {
    background: #3b82f6;
    border-radius: 4px;
  }
  
  .custom-scrollbar::-webkit-scrollbar-thumb:hover {
    background: #2563eb;
  }
`;

const MaterialExcelInterface = () => {
  // UI headers (display headers)
  const HEADERS = ["Email", "Name", "Branch", "Gender", "Passout Year"];

  // API headers (for submission)
  const API_HEADERS = ["mail_id", "name", "branch", "gender", "year"];

  // Define maximum allowed rows
  const MAX_ROWS = 500;

  // Initial data with headers and empty rows
  const [rows, setRows] = useState([
    ["", "", "", "", ""],
    ["", "", "", "", ""],
    ["", "", "", "", ""],
  ]);

  // Add loading states
  const [isLoading, setIsLoading] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [loadingMessage, setLoadingMessage] = useState("");

  // State for column fill feature
  const [columnFillValue, setColumnFillValue] = useState("");
  const [selectedColumnIndex, setSelectedColumnIndex] = useState(2); // Default to Branch column

  // Handle column fill input change
  const handleColumnFillChange = (value) => {
    setColumnFillValue(value);
  };

  // History for undo/redo functionality
  const [history, setHistory] = useState([
    Array(5)
      .fill()
      .map(() => Array(HEADERS.length).fill("")),
  ]);
  const [historyIndex, setHistoryIndex] = useState(0);

  const [activeCell, setActiveCell] = useState({ row: -1, col: -1 });
  const tableRef = useRef(null);

  // Create refs for each cell to improve selection handling
  const cellRefs = useRef([]);

  // Initialize the refs array when rows change
  useEffect(() => {
    cellRefs.current = Array(rows.length)
      .fill()
      .map(() =>
        Array(HEADERS.length)
          .fill()
          .map(() => React.createRef())
      );
  }, [rows.length]);

  // Add to history
  const addToHistory = (newRows) => {
    // If we've done some undos and then make a new change, we should discard the "future" states
    const newHistory = history.slice(0, historyIndex + 1);

    // Add the new state to history
    newHistory.push(JSON.parse(JSON.stringify(newRows)));

    // Limit history to a reasonable size (e.g., 50 states)
    if (newHistory.length > 50) {
      newHistory.shift();
    }

    setHistory(newHistory);
    setHistoryIndex(newHistory.length - 1);
  };

  // Undo function
  const undo = () => {
    if (historyIndex > 0) {
      const newIndex = historyIndex - 1;
      setHistoryIndex(newIndex);
      setRows(JSON.parse(JSON.stringify(history[newIndex])));
    }
  };

  // Redo function
  const redo = () => {
    if (historyIndex < history.length - 1) {
      const newIndex = historyIndex + 1;
      setHistoryIndex(newIndex);
      setRows(JSON.parse(JSON.stringify(history[newIndex])));
    }
  };

  // Handle global keyboard shortcuts
  useEffect(() => {
    const handleGlobalKeyDown = (e) => {
      // Ignore if loading
      if (isLoading || isSubmitting) return;

      // Undo: Ctrl+Z or Cmd+Z
      if ((e.ctrlKey || e.metaKey) && e.key === "z" && !e.shiftKey) {
        e.preventDefault();
        undo();
      }

      // Redo: Ctrl+Y or Cmd+Y or Ctrl+Shift+Z or Cmd+Shift+Z
      if (
        (e.ctrlKey || e.metaKey) &&
        (e.key === "y" || (e.key === "z" && e.shiftKey))
      ) {
        e.preventDefault();
        redo();
      }
    };

    document.addEventListener("keydown", handleGlobalKeyDown);
    return () => {
      document.removeEventListener("keydown", handleGlobalKeyDown);
    };
  }, [historyIndex, history, isLoading, isSubmitting]);

  // Calculate data stats
  const getDataStats = () => {
    // Count non-empty cells
    let nonEmptyCells = 0;
    let nonEmptyRows = 0;

    for (const row of rows) {
      const hasContent = row.some((cell) => cell && cell.trim() !== "");
      if (hasContent) {
        nonEmptyRows++;
        for (const cell of row) {
          if (cell && cell.trim() !== "") {
            nonEmptyCells++;
          }
        }
      }
    }

    return {
      totalRows: rows.length,
      nonEmptyRows,
      nonEmptyCells,
      columnsCount: HEADERS.length,
    };
  };

  // Handle pasting data into the table
  const handlePaste = (e) => {
    if (isLoading || isSubmitting) return;
    if (activeCell.row === -1 || activeCell.col === -1) return;

    e.preventDefault();

    // Show loading state for large data pastes using the in-table loader
    setIsLoading(true);

    try {
      // Get clipboard data
      const clipboardData = e.clipboardData || window.clipboardData;
      if (!clipboardData) {
        toast.error("Clipboard access failed. Please try again.");
        setIsLoading(false);
        return;
      }

      const pastedText = clipboardData.getData("text") || "";
      if (!pastedText.trim()) {
        toast.warning("No text content found in clipboard.");
        setIsLoading(false);
        return;
      }

      // Detect if this is a large paste operation
      const pastedRows = pastedText
        .split(/\r\n|\n|\r/)
        .filter(
          (row) => row !== null && row !== undefined && row.trim() !== ""
        );

      const isLargePaste = pastedText.length > 10000 || pastedRows.length > 50;

      if (isLargePaste) {
        const estimatedCells = pastedRows.reduce((sum, row) => {
          const delimiter = row.includes("\t") ? "\t" : ",";
          return sum + row.split(delimiter).length;
        }, 0);

        toast.info(
          `Processing ${pastedRows.length} rows with approximately ${estimatedCells} cells, please wait...`
        );
        // For large pastes, update the loading message in the in-table loading indicator
        setLoadingMessage(`Processing ${pastedRows.length} rows...`);
      }

      // Process the pasted data safely with timeout
      setTimeout(() => {
        try {
          // Parse the pasted text into rows and columns
          const pastedRows = pastedText
            .split(/\r\n|\n|\r/)
            .filter(
              (row) => row !== null && row !== undefined && row.trim() !== ""
            );

          if (pastedRows.length === 0) {
            toast.warning("No valid data rows found.");
            setIsLoading(false);
            return;
          }

          // Count columns and rows before processing
          const totalRows = pastedRows.length;
          const columnsPerRow = pastedRows.map((row) => {
            const delimiter = row.includes("\t") ? "\t" : ",";
            return row.split(delimiter).length;
          });
          const totalColumns = columnsPerRow.reduce(
            (sum, count) => sum + count,
            0
          );
          const maxColumnsInRow = Math.max(...columnsPerRow);
          const minColumnsInRow = Math.min(...columnsPerRow);

          console.log(
            `Pasting data: ${totalRows} rows, ${totalColumns} total cells, ${minColumnsInRow}-${maxColumnsInRow} columns per row`
          );

          // Safely split rows into columns, handling potential empty strings
          const pastedData = pastedRows.map((row) => {
            // Handle both tab-delimited and comma-delimited data
            const delimiter = row.includes("\t") ? "\t" : ",";
            return row.split(delimiter).map((cell) => cell || "");
          });

          // Skip the header row if it exists in the pasted data
          let dataToInsert = pastedData;

          // Only try to detect headers if we have at least one row
          if (pastedData.length > 0 && pastedData[0].length > 0) {
            // Check if the first row contains headers (case insensitive comparison)
            // Safely handle potential undefined values
            const possibleHeaders = pastedData[0].map((h) =>
              (h || "").toString().toLowerCase()
            );
            const knownHeaders = HEADERS.map((h) => h.toLowerCase());

            // If the first row looks like headers, skip it
            if (possibleHeaders.some((h) => knownHeaders.includes(h))) {
              dataToInsert = pastedData.slice(1);
            }
          }

          // Create a copy of the current rows
          const newRows = [...rows];

          // Check if paste operation would exceed the maximum row limit
          const potentialRowCount = Math.max(
            newRows.length,
            activeCell.row + dataToInsert.length
          );

          if (potentialRowCount > MAX_ROWS) {
            const rowsAvailable = MAX_ROWS - activeCell.row;
            if (rowsAvailable <= 0) {
              toast.error(
                `Row limit of ${MAX_ROWS} reached. Cannot paste at the current position.`
              );
              setIsLoading(false);
              return;
            }

            toast.warning(
              `Row limit of ${MAX_ROWS} reached. Only pasting ${rowsAvailable} of ${dataToInsert.length} rows.`
            );
            dataToInsert = dataToInsert.slice(0, rowsAvailable);
          }

          // Update rows starting from the active cell
          let rowsAdded = 0;

          for (let i = 0; i < dataToInsert.length; i++) {
            const targetRowIndex = activeCell.row + i;

            // Add new rows if needed
            if (targetRowIndex >= newRows.length) {
              newRows.push(Array(HEADERS.length).fill(""));
              rowsAdded++;
            }

            // Make sure we don't exceed the column bounds
            for (
              let j = 0;
              j < dataToInsert[i].length && j + activeCell.col < HEADERS.length;
              j++
            ) {
              // Safely assign the cell value, ensuring it's a string
              const cellValue = dataToInsert[i][j];
              newRows[targetRowIndex][activeCell.col + j] =
                cellValue !== null && cellValue !== undefined
                  ? cellValue.toString()
                  : "";
            }
          }

          // Only update state if we actually processed some data
          if (dataToInsert.length > 0) {
            setRows(newRows);
            addToHistory(newRows);

            // Calculate total cells pasted
            const totalCellsPasted = dataToInsert.reduce(
              (sum, row) =>
                sum + Math.min(row.length, HEADERS.length - activeCell.col),
              0
            );

            toast.success(
              `Pasted ${
                dataToInsert.length
              } rows and ${totalCellsPasted} cells of data${
                rowsAdded > 0 ? ` (added ${rowsAdded} new rows)` : ""
              }`
            );
          } else {
            toast.warning(
              "No data was pasted. Please check your clipboard content."
            );
          }
        } catch (err) {
          console.error("Paste processing error:", err);
          toast.error(
            `Error processing pasted data: ${err.message || "Unknown error"}`
          );
        } finally {
          setIsLoading(false);
        }
      }, 100); // Small delay to ensure loading state is visible
    } catch (err) {
      console.error("Initial paste error:", err);
      toast.error(`Clipboard error: ${err.message || "Unknown error"}`);
      setIsLoading(false);
    }
  };

  // Handle cell click to set active cell
  const handleCellClick = (rowIndex, colIndex) => {
    setActiveCell({ row: rowIndex, col: colIndex });
  };

  // Handle cell content change
  const handleCellChange = (rowIndex, colIndex, value) => {
    const newRows = [...rows];
    if (newRows[rowIndex][colIndex] !== value) {
      newRows[rowIndex][colIndex] = value;
      setRows(newRows);
      addToHistory(newRows);
    }
  };

  // Add a new empty row
  const addRow = () => {
    // Check if adding a row would exceed the maximum limit
    if (rows.length >= MAX_ROWS) {
      toast.error(`Maximum row limit of ${MAX_ROWS} reached.`);
      return;
    }

    const newRows = [...rows, Array(HEADERS.length).fill("")];
    setRows(newRows);
    addToHistory(newRows);
  };

  // Delete the selected row
  const deleteRow = () => {
    if (activeCell.row === -1 || rows.length <= 1) return;

    const newRows = [...rows];
    newRows.splice(activeCell.row, 1);
    setRows(newRows);
    addToHistory(newRows);
    setActiveCell({ row: -1, col: -1 });
  };

  // Clear all data
  const clearData = () => {
    const newRows = Array(5)
      .fill()
      .map(() => Array(HEADERS.length).fill(""));

    setRows(newRows);
    addToHistory(newRows);
    setActiveCell({ row: -1, col: -1 });
  };

  // Fill selected column with value
  const fillSelectedColumn = () => {
    if (isLoading || isSubmitting) return;

    if (!columnFillValue.trim()) {
      toast.warning("Please enter a value to fill the column");
      return;
    }

    // Set loading state for large datasets
    if (rows.length > 50) {
      setIsLoading(true);
    }

    try {
      // Use setTimeout to allow the UI to update with loading state for large datasets
      setTimeout(
        () => {
          try {
            // Create a copy of the current rows
            const newRows = [...rows];

            // Fill the selected column for all rows
            for (let i = 0; i < newRows.length; i++) {
              newRows[i][selectedColumnIndex] = columnFillValue;
            }

            setRows(newRows);
            addToHistory(newRows);

            toast.success(
              `${HEADERS[selectedColumnIndex]} column filled with "${columnFillValue}"`
            );
          } catch (err) {
            console.error("Error filling column:", err);
            toast.error("Error filling column");
          } finally {
            if (rows.length > 50) {
              setIsLoading(false);
            }
          }
        },
        rows.length > 50 ? 100 : 0
      );
    } catch (err) {
      console.error("Error in fill column operation:", err);
      toast.error("Error filling column");
      setIsLoading(false);
    }
  };

  // Convert data to CSV with API headers
  const convertToCSV = (dataRows) => {
    // Create CSV content with API headers instead of UI headers
    return [
      API_HEADERS.join(","),
      ...dataRows
        .filter((row) => row.some((cell) => cell.trim() !== ""))
        .map((row) =>
          row
            .map((cell) => {
              // Escape commas and quotes in cell content
              if (cell.includes(",") || cell.includes('"')) {
                return `"${cell.replace(/"/g, '""')}"`;
              }
              return cell;
            })
            .join(",")
        ),
    ].join("\n");
  };



  // Submit data
  const handleSubmit = async () => {
    // Set loading state
    setIsSubmitting(true);
    setLoadingMessage("Submitting your data...");

    try {
      const filteredRows = rows.filter((row) =>
        row.some((cell) => cell.trim() !== "")
      );
      const csvData = convertToCSV(filteredRows);

      // Log the CSV data for debugging
      console.log("Submitting CSV data:", csvData);

      // Create a file object from the CSV data
      const csvBlob = new Blob([csvData], { type: "text/csv" });
      const csvFile = new File([csvBlob], "intake_data.csv", {
        type: "text/csv",
      });

      // Create FormData to send the file
      const formData = new FormData();
      formData.append("file", csvFile);

      // Submit the CSV data using StaffService
      const response = await StaffService.uploadIntakeData(formData);

      console.log("Success:", response);
      toast.success("Data submitted successfully!");
    } catch (error) {
      console.error("Error submitting data:", error);
      toast.error("Error submitting data. Please try again.");
    } finally {
      setIsSubmitting(false);
    }
  };

  // Handle cut (Ctrl+X)
  const handleCut = () => {
    if (
      isLoading ||
      isSubmitting ||
      activeCell.row === -1 ||
      activeCell.col === -1
    )
      return;

    const cell = cellRefs.current[activeCell.row][activeCell.col].current;
    const cellText = cell.textContent;

    // Copy to clipboard
    navigator.clipboard.writeText(cellText).then(() => {
      // Clear the cell
      const newRows = [...rows];
      newRows[activeCell.row][activeCell.col] = "";
      setRows(newRows);
      addToHistory(newRows);

      cell.textContent = "";
    });
  };

  // Handle copy (Ctrl+C)
  const handleCopy = () => {
    if (activeCell.row === -1 || activeCell.col === -1) return;

    const cellText =
      cellRefs.current[activeCell.row][activeCell.col].current.textContent;

    navigator.clipboard.writeText(cellText);
  };

  // Key handler for navigation using arrow keys and keyboard shortcuts
  const handleKeyDown = (e, rowIndex, colIndex) => {
    if (
      (isLoading || isSubmitting) &&
      !["ArrowUp", "ArrowDown", "ArrowLeft", "ArrowRight", "Tab"].includes(
        e.key
      )
    )
      return;

    // Handle keyboard shortcuts
    if (e.ctrlKey || e.metaKey) {
      switch (e.key) {
        case "c": // Copy
          e.preventDefault();
          handleCopy();
          return;
        case "x": // Cut
          e.preventDefault();
          handleCut();
          return;
        case "v": // Paste is handled by onPaste event
          return;
        default:
          break;
      }
    }

    // Handle navigation keys
    switch (e.key) {
      case "ArrowUp":
        if (rowIndex > 0) {
          e.preventDefault();
          setActiveCell({ row: rowIndex - 1, col: colIndex });
          cellRefs.current[rowIndex - 1][colIndex].current?.focus();
        }
        break;
      case "ArrowDown":
        if (rowIndex < rows.length - 1) {
          e.preventDefault();
          setActiveCell({ row: rowIndex + 1, col: colIndex });
          cellRefs.current[rowIndex + 1][colIndex].current?.focus();
        } else if (rowIndex === rows.length - 1 && rows.length < MAX_ROWS) {
          // Add new row when pressing down from the last row, if under the limit
          e.preventDefault();
          addRow();
          // Set timeout to allow the DOM to update before focusing
          setTimeout(() => {
            setActiveCell({ row: rows.length, col: colIndex });
            cellRefs.current[rows.length]?.[colIndex]?.current?.focus();
          }, 0);
        } else if (rowIndex === rows.length - 1 && rows.length >= MAX_ROWS) {
          // Show a toast if we've hit the limit
          toast.warning(`Row limit of ${MAX_ROWS} reached.`);
        }
        break;
      case "ArrowLeft":
        if (colIndex > 0) {
          e.preventDefault();
          setActiveCell({ row: rowIndex, col: colIndex - 1 });
          cellRefs.current[rowIndex][colIndex - 1].current?.focus();
        }
        break;
      case "ArrowRight":
        if (colIndex < HEADERS.length - 1) {
          e.preventDefault();
          setActiveCell({ row: rowIndex, col: colIndex + 1 });
          cellRefs.current[rowIndex][colIndex + 1].current?.focus();
        }
        break;
      case "Tab":
        e.preventDefault();
        if (e.shiftKey) {
          // Move backward
          if (colIndex > 0) {
            setActiveCell({ row: rowIndex, col: colIndex - 1 });
            cellRefs.current[rowIndex][colIndex - 1].current?.focus();
          } else if (rowIndex > 0) {
            setActiveCell({ row: rowIndex - 1, col: HEADERS.length - 1 });
            cellRefs.current[rowIndex - 1][HEADERS.length - 1].current?.focus();
          }
        } else {
          // Move forward
          if (colIndex < HEADERS.length - 1) {
            setActiveCell({ row: rowIndex, col: colIndex + 1 });
            cellRefs.current[rowIndex][colIndex + 1].current?.focus();
          } else if (rowIndex < rows.length - 1) {
            setActiveCell({ row: rowIndex + 1, col: 0 });
            cellRefs.current[rowIndex + 1][0].current?.focus();
          } else if (rows.length < MAX_ROWS) {
            // Add new row when tabbing from the last cell if under the limit
            addRow();
            // Set timeout to allow the DOM to update before focusing
            setTimeout(() => {
              setActiveCell({ row: rows.length, col: 0 });
              cellRefs.current[rows.length]?.[0]?.current?.focus();
            }, 0);
          } else {
            // Show a toast if we've hit the limit
            toast.warning(`Row limit of ${MAX_ROWS} reached.`);
          }
        }
        break;
      case "Enter":
        if (!e.shiftKey) {
          e.preventDefault();
          if (rowIndex < rows.length - 1) {
            setActiveCell({ row: rowIndex + 1, col: colIndex });
            cellRefs.current[rowIndex + 1][colIndex].current?.focus();
          } else if (rows.length < MAX_ROWS) {
            // Add new row when pressing Enter from the last row if under the limit
            addRow();
            // Set timeout to allow the DOM to update before focusing
            setTimeout(() => {
              setActiveCell({ row: rows.length, col: colIndex });
              cellRefs.current[rows.length]?.[colIndex]?.current?.focus();
            }, 0);
          } else {
            // Show a toast if we've hit the limit
            toast.warning(`Row limit of ${MAX_ROWS} reached.`);
          }
        }
        break;
      default:
        break;
    }
  };

  // Determine button styles based on disabled state and variant
  const getButtonClass = (variant = "default", disabled = false) => {
    const baseClasses =
      "px-4 py-2 rounded-md font-medium transition-colors focus:outline-none focus:ring-2 focus:ring-opacity-50 text-sm";

    if (disabled) {
      return `${baseClasses} bg-gray-200 text-gray-500 cursor-not-allowed`;
    }

    switch (variant) {
      case "contained":
        return `${baseClasses} bg-blue-700 hover:bg-blue-800 text-white focus:ring-blue-500`;
      case "outlined":
        return `${baseClasses} border border-blue-500 hover:bg-blue-50 text-blue-700 focus:ring-blue-500`;
      case "text":
        return `${baseClasses} text-blue-700 hover:bg-blue-50 focus:ring-blue-500`;
      case "error":
        return `${baseClasses} bg-red-600 hover:bg-red-700 text-white focus:ring-red-500`;
      default:
        return `${baseClasses} bg-blue-700 hover:bg-blue-800 text-white focus:ring-blue-500`;
    }
  };

  return (
    <div className="w-full mx-auto p-4 relative">
      {/* Add style tag for custom scrollbar */}
      <style>{scrollbarStyles}</style>

      {/* Toast notifications */}
      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={false}
      />

      {/* Full-Screen Loading Animation - only for submission */}
      <FullScreenLoading isVisible={isSubmitting} message={loadingMessage} />

      <div className="bg-white rounded-lg shadow-lg overflow-hidden">
        {/* Header */}
        <div className="px-6 py-4 bg-[#0288d1] border-b border-blue-800">
          <div className="flex justify-between items-center">
            <div>
              <h2 className="text-xl font-medium text-white">Add Students</h2>
              <p className="text-sm text-blue-100 mt-1">
                Enter data or paste directly from Excel. Headers are fixed and
                won't be affected.
              </p>
            </div>
            <div className="flex space-x-2"></div>
          </div>

          {/* Data Stats Bar */}
          <div className="mt-4 p-2 bg-blue-50 border border-blue-200 rounded-md flex justify-between items-center">
            <div className="flex space-x-4">
              {(() => {
                const stats = getDataStats();
                return (
                  <>
                    <div className="text-sm">
                      <span className="font-semibold text-blue-800">
                        Rows Added: {stats.totalRows} out of {MAX_ROWS}
                      </span>
                    </div>
                  </>
                );
              })()}
            </div>
          </div>
        </div>

        {/* Content with Custom Scrollbar */}
        <div
          className="px-6 py-4 h-96 overflow-y-auto custom-scrollbar relative"
          onPaste={handlePaste}
        >
          {/* In-table loading overlay for paste operations */}
          {isLoading && (
            <div className="absolute inset-0 bg-white bg-opacity-70 flex items-center justify-center z-30">
              <div className="flex flex-col items-center">
                <div className="w-12 h-12 border-4 border-blue-600 border-t-transparent rounded-full animate-spin"></div>
                <p className="mt-2 text-blue-700 font-medium">
                  {loadingMessage || "Processing..."}
                </p>
              </div>
            </div>
          )}

          {/* Table with sticky header and horizontal scrollbar */}
          <div className="overflow-x-auto custom-scrollbar border border-gray-300 rounded-md shadow-sm">
            <table className="w-full border-collapse" ref={tableRef}>
              <thead className="sticky top-0 z-10">
                <tr className="bg-[#0288d1] text-white border-b border-blue-700">
                  {HEADERS.map((header, index) => (
                    <th
                      key={index}
                      className="px-4 py-3 text-left font-medium border-r border-blue-500 last:border-r-0"
                    >
                      {header}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {rows.map((row, rowIndex) => (
                  <tr
                    key={rowIndex}
                    className={`${
                      rowIndex % 2 === 0 ? "bg-white" : "bg-blue-50"
                    } hover:bg-blue-100 ${
                      activeCell.row === rowIndex ? "bg-blue-100" : ""
                    }`}
                  >
                    {row.map((cell, colIndex) => (
                      <td
                        key={colIndex}
                        className={`px-4 py-2 border-r border-b border-gray-300 last:border-r-0 ${
                          activeCell.row === rowIndex &&
                          activeCell.col === colIndex
                            ? "bg-blue-200"
                            : ""
                        }`}
                        style={{ outline: "none" }}
                      >
                        <div
                          ref={cellRefs.current[rowIndex]?.[colIndex] ?? null}
                          contentEditable={!isLoading && !isSubmitting}
                          suppressContentEditableWarning={true}
                          className="outline-none min-h-8 focus:outline-none"
                          onClick={() => handleCellClick(rowIndex, colIndex)}
                          onFocus={() => handleCellClick(rowIndex, colIndex)}
                          onBlur={(e) =>
                            handleCellChange(
                              rowIndex,
                              colIndex,
                              e.target.textContent
                            )
                          }
                          onKeyDown={(e) =>
                            handleKeyDown(e, rowIndex, colIndex)
                          }
                          tabIndex={0}
                          data-row={rowIndex}
                          data-col={colIndex}
                        >
                          {cell}
                        </div>
                      </td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

          {/* Tips */}
          <div className="mt-4 text-sm text-gray-600">
            <p className="font-medium">Tips:</p>
            <ul className="mt-2 space-y-1 text-gray-600 ml-5 list-disc">
              <li>Click on any cell to edit or select it</li>
              <li>Press Tab to move between cells</li>
              <li>Select a cell and paste (Ctrl+V/Cmd+V) to import data</li>
              <li>Use Ctrl+Z/Cmd+Z to undo and Ctrl+Y/Cmd+Y to redo</li>
              <li>
                Use Ctrl+C/Cmd+C to copy and Ctrl+X/Cmd+X to cut cell content
              </li>
              <li>Headers are fixed and won't be overwritten when pasting</li>
              <li>Use arrow keys to navigate between cells</li>
              <li>Press Enter to move to the next row</li>
            </ul>
          </div>
        </div>

        {/* Fixed Submit Button Footer */}
        <div className="px-6 py-4 bg-blue-50 border-t border-blue-100 flex justify-end space-x-2 sticky bottom-0 left-0 right-0 shadow-md z-10">
          <motion.button
            className={getButtonClass("outlined", isLoading || isSubmitting)}
            onClick={clearData}
            disabled={isLoading || isSubmitting}
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
            transition={{ duration: 0.2 }}
          >
            Reset
          </motion.button>
          <motion.button
            className={getButtonClass("contained", isLoading || isSubmitting)}
            onClick={handleSubmit}
            disabled={isLoading || isSubmitting}
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
            transition={{ duration: 0.2 }}
          >
            Submit Data
          </motion.button>
        </div>
      </div>
    </div>
  );
};

export default MaterialExcelInterface;
