import React, { useState, useEffect, useCallback, useMemo } from "react";
import {
  Box,
  TextField,
  Button,
  Autocomplete,
  Paper,
  Typography,
  IconButton,
  Popover,
  CircularProgress,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import FilterListIcon from "@mui/icons-material/FilterList";
import { styled } from "@mui/material/styles";
import { motion } from "framer-motion";
import axios from "axios";
import { BASE_URL } from "../../services/configUrls";

// Styled components
const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(3),
  borderRadius: theme.spacing(2),
  boxShadow: "0 8px 32px rgba(0, 0, 0, 0.1)",
  background: "linear-gradient(145deg, #ffffff, #f5f5f5)",
  overflow: "hidden",
}));

const MainContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  gap: theme.spacing(1),
  alignItems: "center",
}));

const PopoverFormContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  gap: theme.spacing(1.5),
  padding: theme.spacing(2.5),
  width: "350px",
  maxHeight: "80vh",
  overflow: "auto",
}));

const SearchButton = styled(Button)(({ theme }) => ({
  height: "40px",
  minWidth: "40px",
  background: "linear-gradient(45deg, #0088cc 30%, #00a6ed 90%)",
  boxShadow: "0 4px 10px rgba(0, 136, 204, 0.3)",
  transition: "all 0.3s ease",
  "&:hover": {
    transform: "translateY(-2px)",
    boxShadow: "0 6px 14px rgba(0, 136, 204, 0.4)",
  },
}));

const years = ["2020", "2021", "2022", "2023", "2024", "2025"];
const statuses = ["Applied", "Approved", "Rejected", "Pending"];

const SearchForm = ({ onSearch }) => {
  // State for form fields
  const [email, setEmail] = useState("");
  const [selectedStatuses, setSelectedStatuses] = useState([]);
  const [selectedYears, setSelectedYears] = useState([]);

  // State for API data
  const [domains, setDomains] = useState([]);
  const [cohorts, setCohorts] = useState([]);
  const [branches, setBranches] = useState([]);

  // State for selected values - store only IDs/names for better performance
  const [selectedDomainIds, setSelectedDomainIds] = useState([]);
  const [selectedCohortIds, setSelectedCohortIds] = useState([]);
  const [selectedBranchNames, setSelectedBranchNames] = useState([]);

  // Loading state
  const [loading, setLoading] = useState(false);

  // Popover state
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  // Create lookup maps for faster access
  const domainMap = useMemo(() => {
    const map = new Map();
    domains.forEach((domain) => map.set(domain.domain_id, domain));
    return map;
  }, [domains]);

  const cohortMap = useMemo(() => {
    const map = new Map();
    cohorts.forEach((cohort) => map.set(cohort.cohort_id, cohort));
    return map;
  }, [cohorts]);

  const branchMap = useMemo(() => {
    const map = new Map();
    branches.forEach((branch) => map.set(branch.branch_name, branch));
    return map;
  }, [branches]);

  // Convert IDs to objects for Autocomplete value
  const selectedDomains = useMemo(
    () => selectedDomainIds.map((id) => domainMap.get(id)).filter(Boolean),
    [selectedDomainIds, domainMap]
  );

  const selectedCohorts = useMemo(
    () => selectedCohortIds.map((id) => cohortMap.get(id)).filter(Boolean),
    [selectedCohortIds, cohortMap]
  );

  const selectedBranches = useMemo(
    () =>
      selectedBranchNames.map((name) => branchMap.get(name)).filter(Boolean),
    [selectedBranchNames, branchMap]
  );

  // Configure for virtualized lists to reduce rendering load
  const listboxProps = useMemo(
    () => ({
      style: {
        maxHeight: "200px",
        scrollBehavior: "smooth",
      },
      // Consider react-window for extremely large lists
      // This is left commented because actual implementation would require additional dependencies
      // and would need to be tested with your specific data volumes
      /*
    component: 'div',
    componentsProps: {
      role: 'listbox',
      virtualize: true,
      virtualizationOptions: {
        overscanCount: 5,
        itemSize: 36,
      },
    },
    */
    }),
    []
  );

  // Fetch filter options from API
  useEffect(() => {
    const fetchFilterOptions = async () => {
      setLoading(true);
      try {
        const accessToken = localStorage.getItem("accessToken");

        if (!accessToken) {
          console.error("Authentication token not found");
          return;
        }

        const response = await axios.get(
          `${BASE_URL}/internship/studentInformationOptions`,
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          }
        );

        // Process API response
        if (response.data) {
          // Sort domains alphabetically for better UX and to ensure stable list rendering
          const sortedDomains = [...(response.data.domainsResponse || [])].sort(
            (a, b) => a.domain_name.localeCompare(b.domain_name)
          );

          // Sort other fields too for consistency
          const sortedCohorts = [...(response.data.cohortsResponse || [])].sort(
            (a, b) => a.cohort_name.localeCompare(b.cohort_name)
          );

          const sortedBranches = [
            ...(response.data.branchesResponse || []),
          ].sort((a, b) => a.branch_name.localeCompare(b.branch_name));

          // Batch state updates to reduce renders
          setDomains(sortedDomains);
          setCohorts(sortedCohorts);
          setBranches(sortedBranches);
        }
      } catch (error) {
        console.error("Error fetching filter options:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchFilterOptions();
  }, []);

  const handleFilterClick = useCallback((event) => {
    setAnchorEl(event.currentTarget);
  }, []);

  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const handleDomainChange = useCallback((_, newValues) => {
    setSelectedDomainIds(newValues.map((item) => item.domain_id));
  }, []);

  const handleCohortChange = useCallback((_, newValues) => {
    setSelectedCohortIds(newValues.map((item) => item.cohort_id));
  }, []);

  const handleBranchChange = useCallback((_, newValues) => {
    setSelectedBranchNames(newValues.map((item) => item.branch_name));
  }, []);

  const handleStatusChange = useCallback((_, newValues) => {
    setSelectedStatuses(newValues || []);
  }, []);

  const handleYearChange = useCallback((_, newValues) => {
    setSelectedYears(newValues || []);
  }, []);

  const handleSearch = useCallback(() => {
    onSearch({
      domain_id: selectedDomainIds.join(","),
      email,
      status: selectedStatuses.join(","),
      cohort_id: selectedCohortIds.join(","),
      year: selectedYears.join(","),
      branch: selectedBranchNames.join(","),
    });
    handleClose();
  }, [
    onSearch,
    selectedDomainIds,
    email,
    selectedStatuses,
    selectedCohortIds,
    selectedYears,
    selectedBranchNames,
    handleClose,
  ]);

  // Handle key press for email field
  const handleKeyPress = useCallback(
    (e) => {
      if (e.key === "Enter") {
        handleSearch();
      }
    },
    [handleSearch]
  );

  const handleSearchTermChange = useCallback((event) => {
    setEmail(event.target.value);
  }, []);

  // Create a more efficient render for checkboxes to improve performance
  const renderCheckbox = useCallback((selected) => {
    return (
      <Box
        component="span"
        sx={{
          width: 6,
          height: 10,
          borderRight: "2px solid white",
          borderBottom: "2px solid white",
          transform: "rotate(45deg)",
          position: "relative",
          top: -1,
        }}
      />
    );
  }, []);

  // Create a memoized render function for options
  const renderOption = useCallback(
    (props, option, label, selected) => (
      <li {...props} key={option}>
        <Box component="span" sx={{ display: "flex", alignItems: "center" }}>
          <Box
            component="span"
            sx={{
              width: 20,
              height: 20,
              mr: 1,
              border: "1px solid #bdbdbd",
              borderRadius: "2px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              bgcolor: selected ? "#0088cc" : "transparent",
            }}
          >
            {selected && renderCheckbox()}
          </Box>
          {label}
        </Box>
      </li>
    ),
    [renderCheckbox]
  );

  // Active filters count for badge
  const activeFiltersCount = useMemo(() => {
    let count = 0;
    if (selectedDomainIds.length > 0) count++;
    if (selectedStatuses.length > 0) count++;
    if (selectedCohortIds.length > 0) count++;
    if (selectedYears.length > 0) count++;
    if (selectedBranchNames.length > 0) count++;
    return count;
  }, [
    selectedDomainIds,
    selectedStatuses,
    selectedCohortIds,
    selectedYears,
    selectedBranchNames,
  ]);

  // Determine label based on whether user is typing an email
  const searchLabel = email.trim() ? "Search Email" : "Search All";

  return (
    <StyledPaper>
      <Typography
        variant="h6"
        gutterBottom
        sx={{
          mb: 2,
          fontWeight: 600,
          fontSize: "1.1rem",
          background: "linear-gradient(45deg, #0088cc, #00a6ed)",
          WebkitBackgroundClip: "text",
          WebkitTextFillColor: "transparent",
        }}
      >
        Internship Not Applied Students
      </Typography>

      <MainContainer>
        <TextField
          label={searchLabel}
          variant="outlined"
          value={email}
          onChange={handleSearchTermChange}
          onKeyPress={handleKeyPress}
          size="small"
          fullWidth
          sx={{ "& .MuiInputBase-root": { height: "40px" } }}
        />

        {/* <IconButton
          onClick={handleFilterClick}
          sx={{
            backgroundColor: "rgba(0, 136, 204, 0.1)",
            height: "40px",
            width: "40px",
            position: "relative",
          }}
        >
          <FilterListIcon color="primary" />
          {activeFiltersCount > 0 && (
            <Box
              sx={{
                position: "absolute",
                top: 0,
                right: 0,
                backgroundColor: "#f44336",
                color: "white",
                borderRadius: "50%",
                width: "18px",
                height: "18px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                fontSize: "12px",
                fontWeight: "bold",
              }}
            >
              {activeFiltersCount}
            </Box>
          )}
        </IconButton> */}

        <SearchButton variant="contained" size="small" onClick={handleSearch}>
          <SearchIcon />
        </SearchButton>

        {/* Popover for filters */}
        <Popover
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          sx={{
            "& .MuiPaper-root": {
              maxHeight: "80vh",
              overflowY: "auto",
            },
          }}
        >
          <PopoverFormContainer>
            <Typography variant="subtitle2" fontWeight={600} color="primary">
              Filter Options
            </Typography>

            {loading ? (
              <Box sx={{ display: "flex", justifyContent: "center", py: 2 }}>
                <CircularProgress size={24} />
              </Box>
            ) : (
              <>
                {/* Domain Autocomplete with Checkboxes */}
                <Autocomplete
                  multiple
                  value={selectedDomains}
                  onChange={handleDomainChange}
                  options={domains}
                  getOptionLabel={(option) => option.domain_name || ""}
                  renderOption={(props, option) =>
                    renderOption(
                      props,
                      option.domain_id,
                      option.domain_name,
                      selectedDomainIds.includes(option.domain_id)
                    )
                  }
                  size="small"
                  limitTags={2}
                  disableCloseOnSelect
                  filterSelectedOptions={false}
                  sx={{ width: "100%" }}
                  renderInput={(params) => (
                    <TextField {...params} label="Domains" />
                  )}
                  ListboxProps={listboxProps}
                />

                {/* Status Autocomplete with Checkboxes */}
                <Autocomplete
                  multiple
                  value={selectedStatuses}
                  onChange={handleStatusChange}
                  options={statuses}
                  renderOption={(props, option) =>
                    renderOption(
                      props,
                      option,
                      option,
                      selectedStatuses.includes(option)
                    )
                  }
                  size="small"
                  limitTags={2}
                  disableCloseOnSelect
                  filterSelectedOptions={false}
                  sx={{ width: "100%" }}
                  renderInput={(params) => (
                    <TextField {...params} label="Status" />
                  )}
                  ListboxProps={listboxProps}
                />

                {/* Cohort Autocomplete with Checkboxes */}
                <Autocomplete
                  multiple
                  value={selectedCohorts}
                  onChange={handleCohortChange}
                  options={cohorts}
                  getOptionLabel={(option) => option.cohort_name || ""}
                  renderOption={(props, option) =>
                    renderOption(
                      props,
                      option.cohort_id,
                      option.cohort_name,
                      selectedCohortIds.includes(option.cohort_id)
                    )
                  }
                  size="small"
                  limitTags={2}
                  disableCloseOnSelect
                  filterSelectedOptions={false}
                  sx={{ width: "100%" }}
                  renderInput={(params) => (
                    <TextField {...params} label="Cohorts" />
                  )}
                  ListboxProps={listboxProps}
                />

                {/* Year Autocomplete with Checkboxes */}
                <Autocomplete
                  multiple
                  value={selectedYears}
                  onChange={handleYearChange}
                  options={years}
                  renderOption={(props, option) =>
                    renderOption(
                      props,
                      option,
                      option,
                      selectedYears.includes(option)
                    )
                  }
                  size="small"
                  limitTags={2}
                  disableCloseOnSelect
                  filterSelectedOptions={false}
                  sx={{ width: "100%" }}
                  renderInput={(params) => (
                    <TextField {...params} label="Passout Years" />
                  )}
                  ListboxProps={listboxProps}
                />

                {/* Branch Autocomplete with Checkboxes */}
                <Autocomplete
                  multiple
                  value={selectedBranches}
                  onChange={handleBranchChange}
                  options={branches}
                  getOptionLabel={(option) => option.branch_name || ""}
                  renderOption={(props, option) =>
                    renderOption(
                      props,
                      option.branch_id,
                      option.branch_name,
                      selectedBranchNames.includes(option.branch_name)
                    )
                  }
                  size="small"
                  limitTags={2}
                  disableCloseOnSelect
                  filterSelectedOptions={false}
                  sx={{ width: "100%" }}
                  renderInput={(params) => (
                    <TextField {...params} label="Branches" />
                  )}
                  ListboxProps={listboxProps}
                />
              </>
            )}

            <SearchButton
              variant="contained"
              startIcon={<SearchIcon />}
              onClick={handleSearch}
              fullWidth
              disabled={loading}
            >
              APPLY FILTERS
            </SearchButton>
          </PopoverFormContainer>
        </Popover>
      </MainContainer>
    </StyledPaper>
  );
};

export default SearchForm;
