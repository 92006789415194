import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  TextField,
  Button,
  InputAdornment,
  IconButton,
  Drawer,
  Autocomplete,
  Stack,
  Divider,
  Grid,
  Snackbar,
  Alert,
  useTheme,
  useMediaQuery,
  Chip,
  Checkbox,
} from "@mui/material";
import {
  Close as CloseIcon,
  CheckBox as CheckBoxIcon,
  CheckBoxOutlineBlank as CheckBoxOutlineBlankIcon,
} from "@mui/icons-material";

// Material UI Blue color palette
const BLUE = {
  light: "#BBDEFB", // Blue 100
  main: "#2196F3", // Blue 500
  dark: "#1976D2", // Blue 700
};

const AddEducatorDrawer = ({
  open,
  onClose,
  onAdd,
  onUpdate,
  editData,
  isEditing = false,
}) => {
  // Get theme and check if it's dark mode
  const theme = useTheme();
  const isDarkMode = theme.palette.mode === "dark";
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  // State for form inputs
  const [educatorName, setEducatorName] = useState("");
  const [email, setEmail] = useState("");
  const [designation, setDesignation] = useState(null);
  const [mobileNumber, setMobileNumber] = useState("");
  const [branch, setBranch] = useState([]);

  // State for notification
  const [notification, setNotification] = useState({
    open: false,
    message: "",
    severity: "success",
  });

  // Available designations
  const designationOptions = ["Principal", "Spoc", "DSpoc"];

  // Available branches
  const branchOptions = [
    "Computer Science",
    "Electrical Engineering",
    "Mechanical Engineering",
    "Civil Engineering",
    "Electronics & Communication",
    "Information Technology",
    "Chemical Engineering",
    "Biotechnology",
    "Aerospace Engineering",
  ];

  // Checkbox icon for Autocomplete
  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;

  // Load edit data when opened for editing
  useEffect(() => {
    if (isEditing && editData && open) {
      // Use setTimeout to ensure the drawer animation has started
      // This gives a better visual effect when populating the form
      setTimeout(() => {
        setEducatorName(editData.name || "");
        setEmail(editData.email || "");
        setDesignation(editData.type || null);
        setMobileNumber(editData.mobile || "");
        // Handle branch as an array
        setBranch(
          editData.branch
            ? Array.isArray(editData.branch)
              ? editData.branch
              : [editData.branch]
            : []
        );
      }, 100);
    } else if (!isEditing && open) {
      // Reset if opening for a new entry
      resetForm();
    }
  }, [isEditing, editData, open]);

  // Reset form fields
  const resetForm = () => {
    setEducatorName("");
    setEmail("");
    setDesignation(null);
    setMobileNumber("");
    setBranch([]);
  };

  // Handle form submission
  const handleSubmit = () => {
    // Create educator object with form data
    const educatorData = {
      name: educatorName,
      email: email,
      designation: designation,
      mobile: mobileNumber,
      branch: branch,
    };

    // Either update existing or add new
    if (isEditing && onUpdate) {
      onUpdate({ ...educatorData, id: editData.id });
      showNotification("Educator updated successfully", "success");
    } else if (onAdd) {
      onAdd(educatorData);
      showNotification("Educator added successfully", "success");
    }

    // Close drawer and reset form
    handleClose();
  };

  // Handle close
  const handleClose = () => {
    resetForm();
    if (onClose) {
      onClose();
    }
  };

  // Show notification
  const showNotification = (message, severity = "success") => {
    setNotification({
      open: true,
      message,
      severity,
    });
  };

  // Close notification
  const handleNotificationClose = () => {
    setNotification({
      ...notification,
      open: false,
    });
  };

  return (
    <>
      <Drawer
        anchor="right"
        open={open}
        onClose={handleClose}
        PaperProps={{
          sx: {
            width: { xs: "100%", sm: 500 },
            backgroundColor: theme.palette.background.paper,
          },
        }}
      >
        <Box sx={{ p: 3 }}>
          {/* Header */}
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              mb: 2,
              backgroundColor: isDarkMode ? BLUE.dark : BLUE.main,
              color: "white",
              p: 1.5,
              borderRadius: 1,
              boxShadow: isDarkMode
                ? "0 2px 8px rgba(0, 0, 0, 0.3)"
                : "0 2px 8px rgba(0, 0, 0, 0.1)",
            }}
          >
            <Typography variant="h6" sx={{ fontWeight: 500 }}>
              {isEditing ? "Edit Educator" : "Add Educator"}
            </Typography>
            <IconButton
              onClick={handleClose}
              size="small"
              sx={{ color: "white" }}
            >
              <CloseIcon />
            </IconButton>
          </Box>

          <Divider
            sx={{
              mb: 3,
              borderColor: isDarkMode
                ? "rgba(255, 255, 255, 0.12)"
                : "rgba(0, 0, 0, 0.12)",
            }}
          />

          {/* Form */}
          <Grid container spacing={2.5}>
            {/* Left Column */}
            <Grid item xs={12} sm={6}>
              <Stack spacing={2.5}>
                <TextField
                  label="Educator Name"
                  fullWidth
                  size="small"
                  value={educatorName}
                  onChange={(e) => setEducatorName(e.target.value)}
                  required
                  sx={{
                    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                      {
                        borderColor: isDarkMode ? BLUE.light : BLUE.main,
                      },
                    "& .MuiInputLabel-root.Mui-focused": {
                      color: isDarkMode ? BLUE.light : BLUE.main,
                    },
                  }}
                  InputLabelProps={{
                    sx: {
                      color: isDarkMode
                        ? theme.palette.text.secondary
                        : undefined,
                    },
                  }}
                />

                <TextField
                  label="Email Address"
                  fullWidth
                  size="small"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                  type="email"
                  sx={{
                    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                      {
                        borderColor: isDarkMode ? BLUE.light : BLUE.main,
                      },
                    "& .MuiInputLabel-root.Mui-focused": {
                      color: isDarkMode ? BLUE.light : BLUE.main,
                    },
                  }}
                  InputLabelProps={{
                    sx: {
                      color: isDarkMode
                        ? theme.palette.text.secondary
                        : undefined,
                    },
                  }}
                />
              </Stack>
            </Grid>

            {/* Right Column */}
            <Grid item xs={12} sm={6}>
              <Stack spacing={2.5}>
                <Autocomplete
                  id="designation"
                  options={designationOptions}
                  value={designation}
                  onChange={(event, newValue) => {
                    setDesignation(newValue);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Designation"
                      size="small"
                      sx={{
                        "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                          {
                            borderColor: isDarkMode ? BLUE.light : BLUE.main,
                          },
                        "& .MuiInputLabel-root.Mui-focused": {
                          color: isDarkMode ? BLUE.light : BLUE.main,
                        },
                      }}
                      InputLabelProps={{
                        sx: {
                          color: isDarkMode
                            ? theme.palette.text.secondary
                            : undefined,
                        },
                      }}
                    />
                  )}
                  size="small"
                  sx={{
                    "& .MuiAutocomplete-paper": {
                      backgroundColor: theme.palette.background.paper,
                    },
                  }}
                />

                <TextField
                  label="Mobile Number"
                  fullWidth
                  size="small"
                  value={mobileNumber}
                  onChange={(e) => setMobileNumber(e.target.value)}
                  inputProps={{
                    maxLength: 15,
                  }}
                  sx={{
                    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                      {
                        borderColor: isDarkMode ? BLUE.light : BLUE.main,
                      },
                    "& .MuiInputLabel-root.Mui-focused": {
                      color: isDarkMode ? BLUE.light : BLUE.main,
                    },
                  }}
                  InputLabelProps={{
                    sx: {
                      color: isDarkMode
                        ? theme.palette.text.secondary
                        : undefined,
                    },
                  }}
                />
              </Stack>
            </Grid>

            {/* Branch Selection with Checkbox Autocomplete */}
            <Grid item xs={12}>
              <Autocomplete
                multiple
                id="branch"
                options={branchOptions}
                value={branch}
                disableCloseOnSelect
                onChange={(event, newValue) => {
                  setBranch(newValue);
                }}
                renderOption={(props, option, { selected }) => (
                  <li {...props}>
                    <Checkbox
                      icon={icon}
                      checkedIcon={checkedIcon}
                      style={{ marginRight: 8 }}
                      checked={selected}
                      sx={{
                        color: isDarkMode ? BLUE.light : BLUE.main,
                        "&.Mui-checked": {
                          color: isDarkMode ? BLUE.light : BLUE.main,
                        },
                      }}
                    />
                    {option}
                  </li>
                )}
                renderTags={(value, getTagProps) =>
                  value.map((option, index) => (
                    <Chip
                      variant="outlined"
                      label={option}
                      size="small"
                      {...getTagProps({ index })}
                      sx={{
                        backgroundColor: isDarkMode
                          ? "rgba(33, 150, 243, 0.15)"
                          : BLUE.light,
                        borderColor: isDarkMode ? BLUE.light : BLUE.main,
                        color: isDarkMode ? BLUE.light : BLUE.dark,
                        "& .MuiChip-deleteIcon": {
                          color: isDarkMode ? BLUE.light : BLUE.main,
                          "&:hover": {
                            color: isDarkMode ? "white" : BLUE.dark,
                          },
                        },
                      }}
                    />
                  ))
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Branch"
                    placeholder="Select branches"
                    size="small"
                    sx={{
                      "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                        {
                          borderColor: isDarkMode ? BLUE.light : BLUE.main,
                        },
                      "& .MuiInputLabel-root.Mui-focused": {
                        color: isDarkMode ? BLUE.light : BLUE.main,
                      },
                    }}
                    InputLabelProps={{
                      sx: {
                        color: isDarkMode
                          ? theme.palette.text.secondary
                          : undefined,
                      },
                    }}
                  />
                )}
                sx={{
                  "& .MuiAutocomplete-paper": {
                    backgroundColor: theme.palette.background.paper,
                  },
                  "& .MuiAutocomplete-tag": {
                    margin: "2px",
                  },
                }}
              />
            </Grid>
          </Grid>

          {/* Action Buttons */}
          <Box
            sx={{ display: "flex", justifyContent: "flex-end", gap: 2, mt: 4 }}
          >
            <Button
              variant="outlined"
              onClick={handleClose}
              size="small"
              sx={{
                color: isDarkMode ? BLUE.light : BLUE.main,
                borderColor: isDarkMode ? BLUE.light : BLUE.main,
                "&:hover": {
                  borderColor: isDarkMode ? BLUE.main : BLUE.dark,
                  backgroundColor: isDarkMode
                    ? "rgba(33, 150, 243, 0.15)"
                    : "rgba(33, 150, 243, 0.04)",
                },
              }}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              onClick={handleSubmit}
              disabled={
                !educatorName ||
                !email ||
                !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)
              }
              size="small"
              sx={{
                backgroundColor: isDarkMode ? BLUE.dark : BLUE.main,
                "&:hover": {
                  backgroundColor: isDarkMode ? "#1565C0" : BLUE.dark,
                },
                "&.Mui-disabled": {
                  backgroundColor: isDarkMode
                    ? "rgba(25, 118, 210, 0.3)"
                    : "rgba(33, 150, 243, 0.3)",
                },
              }}
            >
              {isEditing ? "Update Educator" : "Add Educator"}
            </Button>
          </Box>
        </Box>
      </Drawer>

      {/* Notification */}
      <Snackbar
        open={notification.open}
        autoHideDuration={3000}
        onClose={handleNotificationClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      >
        <Alert
          onClose={handleNotificationClose}
          severity={notification.severity}
          variant="filled"
          sx={{ width: "100%" }}
        >
          {notification.message}
        </Alert>
      </Snackbar>
    </>
  );
};

export default AddEducatorDrawer;
