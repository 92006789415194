import membership from "../../assets/imgs/Membership-2048x1660.png";
import React, { useState, useEffect } from "react";
import axios from "axios";
import { BASE_URL } from "../../services/configUrls";

const MembershipDisplay = () => {
  const [open, setOpen] = useState(false);
  const [membershipData, setMembershipData] = useState({
    membership_name: "Loading...",
  });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    // Fetch membership data when component mounts
    const fetchMembershipData = async () => {
      try {
        setLoading(true);
        // Get access token from localStorage
        const accessToken = localStorage.getItem("accessToken");

        // Make the API request with the access token in the Authorization header
        const response = await axios.get(
          `${BASE_URL}/internship/spocMembership`,
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          }
        );

        setMembershipData(response.data);
      } catch (err) {
        console.error("Error fetching membership data:", err);
        setError("Failed to load membership data");
      } finally {
        setLoading(false);
      }
    };

    fetchMembershipData();
  }, []);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  // Modal component with Material UI-like styling
  const Modal = ({ open, onClose, children }) => {
    if (!open) return null;

    return (
      <div
        className="fixed inset-0 z-[9999] flex items-center justify-center bg-black bg-opacity-70"
        onClick={onClose}
      >
        <div
          className="bg-white rounded-lg max-w-4xl max-h-screen overflow-auto p-4"
          onClick={(e) => e.stopPropagation()}
        >
          {children}
        </div>
      </div>
    );
  };

  return (
    <div className="max-w-2xl mx-auto p-6">
      {/* Current Membership Section */}
      <div className="mb-8 bg-blue-50 p-4 rounded-lg border border-blue-200">
        {loading ? (
          <p className="text-xl font-medium text-gray-800">
            Loading membership data...
          </p>
        ) : error ? (
          <p className="text-xl font-medium text-red-500">{error}</p>
        ) : (
          <p className="text-xl font-medium text-gray-800">
            Your Current Membership Plan is:{" "}
            <span className="font-bold">{membershipData.membership_name}</span>
          </p>
        )}
      </div>

      {/* Membership Plans Header with Zoomable Image */}
      <div
        className="w-full rounded-lg overflow-hidden cursor-pointer"
        onClick={handleOpen}
      >
        <div className="relative">
          <img
            src={membership}
            alt="Membership Plans"
            className="w-full object-cover"
          />

          {/* Text overlay */}
          <div className="absolute inset-0 flex items-center justify-center">
            <h1 className="text-4xl font-bold text-white tracking-wide">
              Membership Plans
            </h1>
          </div>

          {/* Zoom indicator */}
          <div className="absolute bottom-4 right-4 bg-black bg-opacity-50 text-white px-3 py-1 rounded-full text-sm">
            Click to zoom
          </div>
        </div>
      </div>

      {/* Material UI styled Modal for zoomed image */}
      <Modal open={open} onClose={handleClose}>
        <div className="relative">
          <img
            src={membership}
            alt="Membership Plans"
            className="max-w-full max-h-[80vh] object-contain"
          />
          <div className="absolute inset-0 flex items-center justify-center">
            {/* <h1 className="text-4xl font-bold text-white tracking-wide">
              Membership Plans
            </h1> */}
          </div>
          <button
            onClick={handleClose}
            className="absolute top-2 right-2 w-8 h-8 bg-black bg-opacity-50 text-white rounded-full flex items-center justify-center"
          >
            ✕
          </button>
        </div>
      </Modal>
    </div>
  );
};

export default MembershipDisplay;

// import React, { useState } from "react";

// // Custom styles for scrollbars and animations
// const customStyles = `
//   /* Custom scrollbar styles */
//   ::-webkit-scrollbar {
//     width: 10px;
//     height: 10px;
//   }
  
//   ::-webkit-scrollbar-track {
//     background: #f1f1f1;
//     border-radius: 4px;
//   }
  
//   ::-webkit-scrollbar-thumb {
//     background: #888;
//     border-radius: 4px;
//   }
  
//   ::-webkit-scrollbar-thumb:hover {
//     background: #555;
//   }
  
//   /* Animations */
//   @keyframes fadeIn {
//     from { opacity: 0; }
//     to { opacity: 1; }
//   }
  
//   @keyframes slideInFromLeft {
//     from { transform: translateX(-20px); opacity: 0; }
//     to { transform: translateX(0); opacity: 1; }
//   }
  
//   @keyframes slideInFromRight {
//     from { transform: translateX(20px); opacity: 0; }
//     to { transform: translateX(0); opacity: 1; }
//   }
  
//   @keyframes slideInFromTop {
//     from { transform: translateY(-20px); opacity: 0; }
//     to { transform: translateY(0); opacity: 1; }
//   }
  
//   @keyframes pulse {
//     0% { box-shadow: 0 0 0 0 rgba(59, 130, 246, 0.2); }
//     70% { box-shadow: 0 0 0 10px rgba(59, 130, 246, 0); }
//     100% { box-shadow: 0 0 0 0 rgba(59, 130, 246, 0); }
//   }
  
//   .animate-fadeIn {
//     animation: fadeIn 0.5s ease-out;
//   }
  
//   .animate-slideInFromLeft {
//     animation: slideInFromLeft 0.5s ease-out;
//   }
  
//   .animate-slideInFromRight {
//     animation: slideInFromRight 0.5s ease-out;
//   }
  
//   .animate-slideInFromTop {
//     animation: slideInFromTop 0.5s ease-out;
//   }
  
//   .hover-lift {
//     transition: transform 0.3s ease-out;
//   }
  
//   .hover-lift:hover {
//     transform: translateY(-3px);
//   }
  
//   .benefit-row {
//     transition: background-color 0.3s ease, transform 0.2s ease;
//   }
  
//   .benefit-row:hover {
//     background-color: rgba(59, 130, 246, 0.05) !important;
//     transform: translateX(3px);
//   }
  
//   .legend-item {
//     transition: transform 0.3s ease, box-shadow 0.3s ease;
//   }
  
//   .legend-item:hover {
//     transform: translateY(-2px);
//     box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1);
//   }
// `;

// const ColoredIcon = ({ type }) => {
//   switch (type) {
//     case "✓":
//       return <span className="text-green-600 font-bold text-lg">✓</span>;
//     case "✘":
//       return <span className="text-red-600 font-bold text-lg">✘</span>;
//     case "★":
//       return <span className="text-blue-500 font-bold text-lg">★</span>;
//     case "TBA":
//       return <span className="text-gray-600 font-medium">TBA</span>;
//     default:
//       return <span>{type}</span>;
//   }
// };

// const MembershipTable = () => {
//   // Sample data structure that can be replaced with dynamic data
//   const [tableData, setTableData] = useState({
//     headers: {
//       main: "Programs / Activities",
//       columns: [
//         {
//           name: "Lite Membership",
//           colspan: 1,
//           subColumns: [{ name: "Engineering" }],
//         },
//       ],
//     },
//     rows: [
//       {
//         activity: "Membership Fee for 3 Years",
//         values: ["₹ 1,50,000 + GST"],
//       },
//       {
//         activity: "Subscription MOU & CoE Certificate",
//         values: ["Included"],
//       },
//       {
//         activity: "Virtual Internship",
//         values: ["Up to 1500"],
//       },
//       {
//         activity: "AICTE ATAL - EduSkills FDP",
//         values: ["✘"],
//       },
//       {
//         activity: "Educators to be trained",
//         values: ["★"],
//       },
//       {
//         activity: "Educators Industry Visit",
//         values: ["✘"],
//       },
//       {
//         activity: "Advanced Physical CoE Setup",
//         values: ["✘"],
//       },
//       {
//         activity: "EduSkills Connect (Annual Conclave)",
//         values: ["★"],
//       },
//       {
//         activity: "HR Summit",
//         values: ["★"],
//       },
//       {
//         activity: "Awards & Recognition",
//         values: ["✓"],
//       },
//       {
//         activity: "EduSkills Annual Ranking",
//         values: ["✓"],
//       },
//       {
//         activity: "International Conference",
//         values: ["★"],
//       },
//       {
//         activity: "EduSkills Publication",
//         values: ["✘"],
//       },
//       {
//         activity: "Editorial Board",
//         values: ["✘"],
//       },
//       {
//         activity: "Talent Connect",
//         values: ["✘"],
//       },
//       {
//         activity: "Job Fair",
//         values: ["✘"],
//       },
//       {
//         activity: "TECH CAMP for Students",
//         values: ["★"],
//       },
//       {
//         activity: "Career Talk",
//         values: ["✓"],
//       },
//       {
//         activity: "Student Innovative Project Challenge (SIPC)",
//         values: ["✘"],
//       },
//       {
//         activity: "Rising Stars for Students",
//         values: ["✓"],
//       },
//       {
//         activity: "EduSkills Academy Internship",
//         values: ["✓"],
//       },
//       {
//         activity: "Institute Insights",
//         values: ["★"],
//       },
//       {
//         activity: "Skill Test for Students",
//         values: ["TBA"],
//       },
//       {
//         activity: "One Student One Mentor",
//         values: ["TBA"],
//       },
//     ],
//     legend: [
//       { symbol: "✓", meaning: "Eligible / Applicable" },
//       { symbol: "TBA", meaning: "To Be Announced Soon" },
//       { symbol: "★", meaning: "Chargeable Services" },
//       { symbol: "✘", meaning: "Not Applicable" },
//     ],
//   });

//   return (
//     <div className="p-6 rounded-lg  animate-fadeIn">
//       {/* Add the custom styles */}
//       <style>{customStyles}</style>
//       {/* Simple clean header */}
//       <div className="w-full mb-6">
//         <div className="flex items-center mb-2 animate-slideInFromLeft">
//           <div className="w-2 h-8 bg-blue-500 rounded mr-3"></div>
//           <h2 className="text-xl font-bold text-gray-800">Your Current Plan</h2>
//         </div>
//       </div>

//       {/* Content container */}
//       <div className="flex flex-col lg:flex-row gap-6 relative">
//         {/* Plan card */}
//         <div className="lg:w-3/4 animate-slideInFromLeft" style={{ animationDelay: "0.1s" }}>
//           <div className="bg-white rounded-lg shadow-sm border border-gray-200 overflow-hidden hover-lift">
//             {/* Plan header */}
//             <div className="bg-blue-50 p-4 border-b border-gray-200" style={{ animation: "pulse 2s infinite" }}>
//               <div className="flex flex-wrap items-center justify-between">
//                 <h3 className="text-lg font-bold text-blue-800">Lite Membership</h3>
//                 <div className="bg-blue-100 text-blue-800 rounded-full px-3 py-1 text-sm font-medium hover-lift">Engineering</div>
//               </div>
//               <p className="text-sm text-gray-600 mt-2">3 Year Membership</p>
//             </div>

//             {/* Benefits list in simple style */}
//             <div className="overflow-x-auto">
//               <div className="min-w-full p-2 max-h-[60vh] overflow-y-auto" style={{ scrollbarWidth: 'thin' }}>
//                 {tableData.rows.slice(1).map((row, rowIndex) => (
//                   <div 
//                     key={rowIndex} 
//                     className={`flex justify-between items-center py-3 px-4 ${
//                       rowIndex % 2 === 0 ? "bg-gray-50" : "bg-white"
//                     } border-b border-gray-100 benefit-row`}
//                     style={{ animationDelay: `${0.05 * rowIndex}s` }}
//                   >
//                     <div className="text-sm md:text-base text-gray-700">{row.activity}</div>
//                     <div className="ml-4 flex-shrink-0">
//                       {row.values.map((value, valueIndex) => (
//                         <div key={valueIndex} className="text-center min-w-16">
//                           {value === "✓" || value === "✘" || value === "★" || value === "TBA" ? (
//                             <ColoredIcon type={value} />
//                           ) : (
//                             <span className="text-blue-600 font-medium">{value}</span>
//                           )}
//                         </div>
//                       ))}
//                     </div>
//                   </div>
//                 ))}
//               </div>
//             </div>
//           </div>
//         </div>

//         {/* Simple legend sidebar */}
//         <div className="lg:w-1/4 animate-slideInFromRight" style={{ animationDelay: "0.2s" }}>
//           <div className="bg-white p-4 rounded-lg shadow-sm border border-gray-200 sticky top-4 max-h-[60vh] overflow-y-auto">
            
            
//             <div className="flex flex-col gap-2">
//               {tableData.legend.map((item, index) => {
//                 let borderColor = "border-blue-300";
//                 let bgColor = "bg-blue-50";

//                 if (item.symbol === "✓") {
//                   borderColor = "border-green-300";
//                   bgColor = "bg-green-50";
//                 } else if (item.symbol === "✘") {
//                   borderColor = "border-red-300";
//                   bgColor = "bg-red-50";
//                 } else if (item.symbol === "TBA") {
//                   borderColor = "border-gray-300";
//                   bgColor = "bg-gray-50";
//                 }

//                 return (
//                   <div
//                     key={index}
//                     className={`flex items-center rounded py-2 px-3 ${bgColor} border-l-2 ${borderColor} legend-item`}
//                     style={{ animationDelay: `${0.1 * index}s` }}
//                   >
//                     <span className="mr-3 w-6 text-center">
//                       <ColoredIcon type={item.symbol} />
//                     </span>
//                     <span className="text-sm text-gray-700">{item.meaning}</span>
//                   </div>
//                 );
//               })}
//             </div>

//             {/* Terms and conditions */}
//             <div className="mt-4 text-xs text-gray-600 space-y-2 border-t border-gray-200 pt-4">
//               <h4 className="text-sm font-medium text-gray-700 mb-2">Terms & Conditions</h4>
//               <p className="flex items-start">
//                 <span className="text-red-500 mr-2">★</span>
//                 <span>T&C Applicable</span>
//               </p>
//               <p className="flex items-start">
//                 <span className="text-red-500 mr-2">★</span>
//                 <span>
//                   The Membership Fees are Subject to Change without Prior
//                   Notice
//                 </span>
//               </p>
//             </div>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default MembershipTable;