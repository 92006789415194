import React from 'react';

const ComingSoonPage = () => {
  return (
    <div className="min-h-screen bg-gray-200 flex items-center justify-center p-4">
      <h1 className="text-6xl font-bold text-gray-800">
        Coming Soon
      </h1>
    </div>
  );
};

export default ComingSoonPage;