import React, { useState } from "react";
import {
  Box,
  Typography,
  TextField,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Avatar,
  IconButton,
  Chip,
  Paper,
  InputAdornment,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Tooltip,
  Snackbar,
  Alert,
  useTheme,
  Popper,
  Fade,
} from "@mui/material";
import {
  Search as SearchIcon,
  Add as AddIcon,
  FileCopy as FileCopyIcon,
  Edit as EditIcon,
  Delete as DeleteIcon,
  MoreHoriz as MoreHorizIcon,
} from "@mui/icons-material";
import AddEducatorDrawer from "./AddEducatorDrawer";

// Material UI Blue colors
const BLUE = {
  solight: "#EEF7FE",
  light: "#1976D2", // Blue 100
  main: "#2196F3", // Blue 500
  dark: "#1976D2", // Blue 700
  white: "#FFFFFF",
};

const MembersList = () => {
  const theme = useTheme();
  const isDarkMode = theme.palette.mode === "dark";

  // State for drawer
  const [drawerOpen, setDrawerOpen] = useState(false);

  // State for dialog
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [memberToDelete, setMemberToDelete] = useState(null);

  // State for editing
  const [isEditing, setIsEditing] = useState(false);
  const [editData, setEditData] = useState(null);

  // State for search
  const [searchQuery, setSearchQuery] = useState("");

  // State for branch popper
  const [branchPopperAnchorEl, setBranchPopperAnchorEl] = useState(null);
  const [popperMember, setPopperMember] = useState(null);
  const branchPopperOpen = Boolean(branchPopperAnchorEl);

  // State for notification
  const [notification, setNotification] = useState({
    open: false,
    message: "",
    severity: "success",
  });

  // State for members
  const [members, setMembers] = useState([
    {
      id: 1,
      name: "M Srittam Kumar",
      email: "kumarmrsrittam@gmail.com",
      type: "Principal",
      initial: "M",
      branch: [
        "Computer Science",
        "Information Technology",
        "Artificial Intelligence",
        "Data Science",
      ],
    },
    {
      id: 2,
      name: "Coding Surya",
      email: "codingsurya335@gmail.com",
      type: "",
      initial: "C",
      branch: ["Electrical Engineering"],
    },
    {
      id: 3,
      name: "padmalochan sahu",
      email: "sahupadmalochan2001@gmail.com",
      type: "",
      initial: "p",
      branch: ["Computer Science"],
    },
    {
      id: 4,
      name: "Polampalli Tejasri",
      email: "tejasripolampalli03@gmail.com",
      type: "",
      initial: "P",
      branch: [
        "Mechanical Engineering",
        "Civil Engineering",
        "Aerospace Engineering",
      ],
    },
    {
      id: 5,
      name: "Pratyukti Dhal",
      email: "dhalpratyukti@gmail.com",
      type: "",
      initial: "P",
      branch: ["Civil Engineering"],
    },
    {
      id: 6,
      name: "sagarabala sipi",
      email: "sagarabalasipi@gmail.com",
      type: "",
      initial: "S",
      branch: ["Electronics & Communication"],
    },
  ]);

  // Filtered members based on search
  const filteredMembers = members.filter((member) => {
    const query = searchQuery.toLowerCase();
    return (
      member.name.toLowerCase().includes(query) ||
      member.email.toLowerCase().includes(query) ||
      (member.type && member.type.toLowerCase().includes(query)) ||
      (member.branch &&
        member.branch.some((b) => b.toLowerCase().includes(query)))
    );
  });

  // Delete handlers
  const handleDeleteClick = (member) => {
    setMemberToDelete(member);
    setDeleteDialogOpen(true);
  };

  const handleDeleteConfirm = () => {
    if (memberToDelete) {
      setMembers(members.filter((member) => member.id !== memberToDelete.id));
      setDeleteDialogOpen(false);
      setMemberToDelete(null);
      showNotification("Educator deleted successfully");
    }
  };

  const handleDeleteCancel = () => {
    setDeleteDialogOpen(false);
    setMemberToDelete(null);
  };

  // Handle adding a new educator
  const handleAddEducator = (newEducator) => {
    // Create a new educator with required fields
    const newMember = {
      id: members.length + 1,
      name: newEducator.name,
      email: newEducator.email,
      type: newEducator.designation || "",
      initial: newEducator.name.charAt(0).toUpperCase(),
      branch: newEducator.branch || [],
      // Additional fields
      mobile: newEducator.mobile,
    };

    // Add to members array
    setMembers([...members, newMember]);
  };

  // Handle updating an educator
  const handleUpdateEducator = (updatedEducator) => {
    const updatedMembers = members.map((member) =>
      member.id === updatedEducator.id
        ? {
            ...member,
            name: updatedEducator.name,
            email: updatedEducator.email,
            type: updatedEducator.designation || "",
            initial: updatedEducator.name.charAt(0).toUpperCase(),
            branch: updatedEducator.branch || [],
            mobile: updatedEducator.mobile,
          }
        : member
    );

    setMembers(updatedMembers);
  };

  // Handle drawer close
  const handleDrawerClose = () => {
    setDrawerOpen(false);
    setIsEditing(false);
    setEditData(null);
  };

  // Handle branch popper
  const handleBranchMouseEnter = (event, member) => {
    if (member.branch && member.branch.length > 1) {
      setBranchPopperAnchorEl(event.currentTarget);
      setPopperMember(member);
    }
  };

  const handleBranchMouseLeave = () => {
    setBranchPopperAnchorEl(null);
    setPopperMember(null);
  };

  // Show notification
  const showNotification = (message, severity = "success") => {
    setNotification({
      open: true,
      message,
      severity,
    });
  };

  // Close notification
  const handleNotificationClose = () => {
    setNotification({
      ...notification,
      open: false,
    });
  };

  // Handle copy email
  const handleCopyEmails = () => {
    if (!members || members.length === 0) return;

    const allEmails = members.map((member) => member.email).join(", ");
    navigator.clipboard
      .writeText(allEmails)
      .then(() => {
        showNotification("All email addresses copied to clipboard");
      })
      .catch((err) => {
        console.error("Failed to copy emails: ", err);
        showNotification("Failed to copy email addresses", "error");
      });
  };

  // Handle copy individual email
  const handleCopyIndividualEmail = (email) => {
    navigator.clipboard
      .writeText(email)
      .then(() => {
        showNotification("Email address copied to clipboard");
      })
      .catch((err) => {
        console.error("Failed to copy email: ", err);
        showNotification("Failed to copy email address", "error");
      });
  };

  // Function to determine avatar background color
  const getAvatarColor = (initial) => {
    const colors = {
      M: "#FFF3E0", // orange light
      C: "#E3F2FD", // blue light
      p: "#9C27B0", // purple
      P: "#E91E63", // pink
      S: "#009688", // teal
    };
    return colors[initial] || BLUE.light; // default blue light
  };

  // Function to determine text color in avatar
  const getTextColor = (initial) => {
    const colors = {
      p: "#FFFFFF", // white
      P: "#FFFFFF", // white
      S: "#FFFFFF", // white
    };
    return colors[initial] || "#212121"; // default dark
  };

  // Function to render branch chips with truncation if needed
  const renderBranchChips = (member) => {
    if (!member.branch || member.branch.length === 0) return null;

    // Show first branch and a count indicator if more exist
    return (
      <Box
        sx={{
          display: "flex",
          flexWrap: "wrap",
          gap: 0.5,
          position: "relative",
        }}
        onMouseEnter={(e) => handleBranchMouseEnter(e, member)}
        onMouseLeave={handleBranchMouseLeave}
      >
        <Chip
          size="small"
          label={member.branch[0]}
          sx={{
            bgcolor: isDarkMode ? "rgba(33, 150, 243, 0.2)" : BLUE.solight,
            color: isDarkMode ? BLUE.light : BLUE.dark,
            fontWeight: 500,
            fontSize: "0.75rem",
            height: 24,
            "& .MuiChip-label": {
              px: 1,
            },
            my: 0.25,
          }}
        />

        {member.branch.length > 1 && (
          <Chip
            size="small"
            icon={<MoreHorizIcon fontSize="small" />}
            label={`+${member.branch.length - 1} more`}
            sx={{
              bgcolor: isDarkMode
                ? "rgba(33, 150, 243, 0.1)"
                : BLUE.solight,
              color: isDarkMode ? BLUE.light : BLUE.dark,
              fontWeight: 500,
              fontSize: "0.75rem",
              height: 24,
              "& .MuiChip-label": {
                px: 1,
              },
              my: 0.25,
              cursor: "pointer",
            }}
          />
        )}
      </Box>
    );
  };

  return (
    <Box sx={{ maxWidth: "1000px", margin: "0 auto", p: 3 }}>
      {/* Header */}
      <Box sx={{ mb: 2 }}>
        <Typography
          variant="h5"
          sx={{
            fontWeight: "bold",
            color: isDarkMode ? BLUE.light : BLUE.dark,
          }}
        >
          Educators
        </Typography>
        <Typography variant="body2" color="text.secondary">
          {filteredMembers.length} Educators{" "}
          {searchQuery && `(filtered from ${members.length})`}
        </Typography>
      </Box>

      {/* Search and Add row */}
      <Box sx={{ display: "flex", mb: 3, gap: 2 }}>
        <TextField
          placeholder="Search members"
          variant="outlined"
          fullWidth
          size="small"
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon fontSize="small" color="action" />
              </InputAdornment>
            ),
            sx: { borderRadius: 28, pr: 2 },
          }}
          sx={{
            "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
              {
                borderColor: BLUE.main,
              },
          }}
        />
        <Button
          variant="outlined"
          onClick={() => setDrawerOpen(true)}
          startIcon={<AddIcon />}
          sx={{
            borderRadius: 28,
            textTransform: "none",
            px: 2,
            whiteSpace: "nowrap",
            color: isDarkMode ? BLUE.light : BLUE.main,
            borderColor: isDarkMode ? BLUE.light : BLUE.main,
            "&:hover": {
              borderColor: BLUE.dark,
              backgroundColor: isDarkMode
                ? "rgba(33, 150, 243, 0.15)"
                : "rgba(33, 150, 243, 0.04)",
            },
          }}
        >
          Add
        </Button>
      </Box>

      {/* Table */}
      <TableContainer
        component={Paper}
        elevation={0}
        sx={{
          border: "1px solid",
          borderColor: isDarkMode ? "rgba(255, 255, 255, 0.12)" : "#f0f0f0",
        }}
      >
        <Table>
          <TableHead>
            <TableRow
              sx={{
                backgroundColor: isDarkMode
                  ? "rgba(33, 150, 243, 0.15)"
                  : BLUE.light,
              }}
            >
              <TableCell
                sx={{
                  width: "25%",
                  color: isDarkMode ? BLUE.light : BLUE.white,
                  fontWeight: 600,
                  fontSize: "0.875rem",
                }}
              >
                Name
              </TableCell>
              <TableCell
                sx={{
                  width: "30%",
                  color: isDarkMode ? BLUE.light : BLUE.white,
                  fontWeight: 600,
                  fontSize: "0.875rem",
                }}
              >
                Email
              </TableCell>
              <TableCell
                sx={{
                  width: "15%",
                  color: isDarkMode ? BLUE.light : BLUE.white,
                  fontWeight: 600,
                  fontSize: "0.875rem",
                }}
              >
                Type
              </TableCell>
              <TableCell
                sx={{
                  width: "22%",
                  color: isDarkMode ? BLUE.light : BLUE.white,
                  fontWeight: 600,
                  fontSize: "0.875rem",
                }}
              >
                Branch
              </TableCell>
              <TableCell
                align="center"
                sx={{
                  width: "8%",
                  color: isDarkMode ? BLUE.light : BLUE.white,
                }}
              >
                <Tooltip title="Copy all email addresses">
                  <IconButton size="small" onClick={handleCopyEmails}>
                    <FileCopyIcon fontSize="small" color="action" />
                  </IconButton>
                </Tooltip>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredMembers.length > 0 ? (
              filteredMembers.map((member) => (
                <TableRow key={member.id} hover>
                  <TableCell>
                    <Box sx={{ display: "flex", flexDirection: "column" }}>
                      <Box sx={{ display: "flex", alignItems: "center" }}>
                        <Avatar
                          sx={{
                            bgcolor: getAvatarColor(member.initial),
                            color: getTextColor(member.initial),
                            width: 32,
                            height: 32,
                            fontSize: "0.875rem",
                            fontWeight: 500,
                          }}
                        >
                          {member.initial}
                        </Avatar>
                        <Typography variant="body2" sx={{ ml: 1.5 }}>
                          {member.name}
                        </Typography>
                      </Box>

                      {/* Action buttons that appear on hover */}
                      <Box
                        sx={{
                          display: "flex",
                          ml: 5,
                          mt: 0.5,
                          gap: 1,
                          maxHeight: "0px",
                          overflow: "hidden",
                          opacity: 0,
                          transition: "all 0.3s ease",
                          ".MuiTableRow-root:hover &": {
                            maxHeight: "30px",
                            opacity: 1,
                          },
                        }}
                      >
                        <Tooltip title="Edit Educator">
                          <IconButton
                            size="small"
                            onClick={() => {
                              setIsEditing(true);
                              setEditData(member);
                              setDrawerOpen(true);
                            }}
                            sx={{
                              color: BLUE.main,
                              padding: "4px",
                              backgroundColor: BLUE.solight,
                              "&:hover": {
                                backgroundColor: `${BLUE.solight}`,
                                opacity: 0.9,
                              },
                            }}
                          >
                            <EditIcon fontSize="small" />
                          </IconButton>
                        </Tooltip>
                        <Tooltip title="Delete Educator">
                          <IconButton
                            size="small"
                            onClick={() => handleDeleteClick(member)}
                            sx={{
                              color: "#f44336",
                              padding: "4px",
                              backgroundColor: "#ffebee",
                              "&:hover": {
                                backgroundColor: "#ffebee",
                                opacity: 0.9,
                              },
                            }}
                          >
                            <DeleteIcon fontSize="small" />
                          </IconButton>
                        </Tooltip>
                        <Tooltip title="Copy Email Address">
                          <IconButton
                            size="small"
                            onClick={() =>
                              handleCopyIndividualEmail(member.email)
                            }
                            sx={{
                              color: BLUE.dark,
                              padding: "4px",
                              backgroundColor: "#f5f5f5",
                              "&:hover": {
                                backgroundColor: "#f5f5f5",
                                opacity: 0.9,
                              },
                            }}
                          >
                            <FileCopyIcon fontSize="small" />
                          </IconButton>
                        </Tooltip>
                      </Box>
                    </Box>
                  </TableCell>
                  <TableCell>
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <Typography
                        variant="body2"
                        color="text.secondary"
                        sx={{ flex: 1 }}
                      >
                        {member.email}
                      </Typography>
                    </Box>
                  </TableCell>
                  <TableCell>
                    {member.type && (
                      <Chip
                        size="small"
                        label={member.type}
                        sx={{
                          bgcolor:
                            member.type === "Space manager"
                              ? "#E8F5E9"
                              : BLUE.solight,
                          color:
                            member.type === "Space manager"
                              ? "#2E7D32"
                              : BLUE.dark,
                          fontWeight: 500,
                          fontSize: "0.75rem",
                          height: 24,
                          "& .MuiChip-label": {
                            px: 1,
                          },
                        }}
                        icon={
                          <Box
                            sx={{
                              width: 6,
                              height: 6,
                              bgcolor:
                                member.type === "Space manager"
                                  ? "#2E7D32"
                                  : BLUE.dark,
                              borderRadius: "50%",
                              ml: 1,
                            }}
                          />
                        }
                      />
                    )}
                  </TableCell>
                  <TableCell>{renderBranchChips(member)}</TableCell>
                  <TableCell>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        visibility: "hidden",
                      }}
                    >
                      {/* Placeholder to maintain table layout */}
                      <Box sx={{ width: 28, height: 28 }} />
                    </Box>
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={5} align="center" sx={{ py: 3 }}>
                  <Typography variant="body1" color="text.secondary">
                    No educators found matching your search.
                  </Typography>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>

      {/* Branch Popper */}
      <Popper
        open={branchPopperOpen}
        anchorEl={branchPopperAnchorEl}
        placement="top-start"
        transition
        sx={{ zIndex: 1300 }}
      >
        {({ TransitionProps }) => (
          <Fade {...TransitionProps} timeout={350}>
            <Paper
              elevation={3}
              sx={{
                p: 2,
                mt: -1,
                mb: 2,
                maxWidth: 320,
                backgroundColor: isDarkMode
                  ? theme.palette.background.paper
                  : "white",
                border: "1px solid",
                borderColor: isDarkMode
                  ? "rgba(255, 255, 255, 0.12)"
                  : "rgba(0, 0, 0, 0.08)",
              }}
            >
              <Typography
                variant="subtitle2"
                sx={{ mb: 1, color: isDarkMode ? BLUE.light : BLUE.dark }}
              >
                All Branches
              </Typography>
              <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                {popperMember?.branch.map((branch, index) => (
                  <Chip
                    key={index}
                    size="small"
                    label={branch}
                    sx={{
                      bgcolor: isDarkMode
                        ? "rgba(33, 150, 243, 0.2)"
                        : BLUE.solight,
                      color: isDarkMode ? BLUE.light : BLUE.dark,
                      fontWeight: 500,
                      fontSize: "0.75rem",
                      height: 24,
                      "& .MuiChip-label": {
                        px: 1,
                      },
                      my: 0.25,
                    }}
                  />
                ))}
              </Box>
            </Paper>
          </Fade>
        )}
      </Popper>

      {/* Add/Edit Educator Drawer */}
      <AddEducatorDrawer
        open={drawerOpen}
        onClose={handleDrawerClose}
        onAdd={handleAddEducator}
        onUpdate={handleUpdateEducator}
        editData={editData}
        isEditing={isEditing}
      />

      {/* Delete Confirmation Dialog */}
      <Dialog open={deleteDialogOpen} onClose={handleDeleteCancel}>
        <DialogTitle
          sx={{
            backgroundColor: isDarkMode ? "#4a0b0b" : "#ffebee",
            color: isDarkMode ? "#ff8a80" : "#d32f2f",
            fontWeight: 500,
          }}
        >
          Delete Educator
        </DialogTitle>
        <DialogContent sx={{ pt: 2, pb: 1, px: 3, minWidth: "300px" }}>
          <DialogContentText>
            Are you sure you want to delete{" "}
            <strong>{memberToDelete?.name}</strong>? This action cannot be
            undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions sx={{ px: 3, pb: 2 }}>
          <Button onClick={handleDeleteCancel} sx={{ color: "text.secondary" }}>
            Cancel
          </Button>
          <Button
            onClick={handleDeleteConfirm}
            variant="contained"
            sx={{
              backgroundColor: isDarkMode ? "#b71c1c" : "#f44336",
              "&:hover": {
                backgroundColor: isDarkMode ? "#7f0000" : "#d32f2f",
              },
            }}
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>

      {/* Notification */}
      <Snackbar
        open={notification.open}
        autoHideDuration={2000}
        onClose={handleNotificationClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      >
        <Alert
          onClose={handleNotificationClose}
          severity={notification.severity}
          variant="filled"
          sx={{ width: "100%" }}
        >
          {notification.message}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default MembersList;
